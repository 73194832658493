import React from 'react';
import axios from "axios";
import * as yup from 'yup';
import {
    Card,
    CardContent,
    CardMedia,
    Collapse,
    Grid,
    MenuItem,
    Stack,
    TextField,
    Button,
    Tab,
    Tabs,
    InputAdornment,
    Box,
    Typography,
    Chip,
    Divider,
    Checkbox
} from "@mui/material";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import EncabezadoImagen from './Images/novanet.png';
import Logo from './Images/NOVANETLOGO.png';

import InputMask from 'react-input-mask';
import { ToastInfo, ToastWarningPersonalizado, ToastErrorPersonalizado, ToastSuccessPersonalizado } from '../Toast/toastsFunctions';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Email, Person, Phone, PinDrop } from '@mui/icons-material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PagoService from '../Services/PagoService';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import Settings from "@pixelpay/sdk-core/lib/models/Settings";
import PixelPayCard from "@pixelpay/sdk-core/lib/models/Card";
import Billing from "@pixelpay/sdk-core/lib/models/Billing";
import Item from "@pixelpay/sdk-core/lib/models/Item";
import Order from "@pixelpay/sdk-core/lib/models/Order";
import SaleTransaction from "@pixelpay/sdk-core/lib/requests/SaleTransaction";
import Transaction from "@pixelpay/sdk-core/lib/services/Transaction";
import TransactionResult from "@pixelpay/sdk-core/lib/entities/TransactionResult";
import { Autocomplete } from '@mui/material';
import events from 'material/src/mixin/events';

const schematab1 = yup.object().shape({
    cardNumber: yup.string().required('El número de tarjeta es requerido'),
    expiryMonth: yup.string().required('El mes de expiración es requerido'),
    expiryYear: yup.string().required('El año de expiración es requerido'),
    cvv: yup.string().required('El CVV es requerido').min(3, 'El CVV debe tener al menos 3 caracteres'),
    cardholder: yup.string().required('El nombre del titular de la tarjeta es requerido'),
    billingAddress: yup.string().required('La dirección es requerida'),
    billingPhone: yup.string().required('El teléfono es requerido'),
    email: yup.string().required('El Correo electrónico es requerido')
});

function PrimerPago() {
    const PagoServices = PagoService()
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const id = queryParams.id || null;

    const [activeStep, setActiveStep] = React.useState(0);
    const [prestamos, setPrestamos] = React.useState([]);
    const [prestamosSelected, setPrestamosSelected] = React.useState([]);

    const [prestamo, setPrestamo] = React.useState(0);
    const [fcIdentidad, setfcIdentidad] = React.useState('');


    const [cardNumber, setCardNumber] = React.useState('');
    const [expiryMonth, setExpiryMonth] = React.useState('');
    const [expiryYear, setExpiryYear] = React.useState('');
    const [cvv, setCvv] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [cardholder, setCardholder] = React.useState('');

    const [price, setprice] = React.useState('0.00');
    const [priceD, setpriceD] = React.useState('0.00');

    const [customer_name, setcustomer_name] = React.useState('');
    const [billingAddress, setBillingAddress] = React.useState('');

    const [billingPhone, setBillingPhone] = React.useState('');
    const [title, settitle] = React.useState('Paquete de Internet Novanet');
    const [fiIDUnicoPrestamo, setfiIDUnicoPrestamo] = React.useState('');


    const [currency] = React.useState('HNL');

    const [errors, setErrors] = React.useState({});

    const [check, setcheck] = React.useState(false);


    const [isFlipped, setIsFlipped] = React.useState(false);

    const months = [
        { value: '01', label: '01 - Enero' },
        { value: '02', label: '02 - Febrero' },
        { value: '03', label: '03 - Marzo' },
        { value: '04', label: '04 - Abril' },
        { value: '05', label: '05 - Mayo' },
        { value: '06', label: '06 - Junio' },
        { value: '07', label: '07 - Julio' },
        { value: '08', label: '08 - Agosto' },
        { value: '09', label: '09 - Septiembre' },
        { value: '10', label: '10 - Octubre' },
        { value: '11', label: '11 - Noviembre' },
        { value: '12', label: '12 - Diciembre' },
    ];

    const years = [
        { value: '2024', label: '2024' },
        { value: '2025', label: '2025' },
        { value: '2026', label: '2026' },
        { value: '2027', label: '2027' },
        { value: '2028', label: '2028' },
        { value: '2029', label: '2029' },
        { value: '2030', label: '2030' },
        { value: '2031', label: '2031' },
        { value: '2032', label: '2032' },
        { value: '2033', label: '2033' },
        { value: '2034', label: '2034' },
        { value: '2035', label: '2035' },
        { value: '2036', label: '2036' },
        { value: '2037', label: '2037' },
        { value: '2038', label: '2038' },
        { value: '2039', label: '2039' },
        { value: '2040', label: '2040' },
        { value: '2041', label: '2041' },
        { value: '2042', label: '2042' },
        { value: '2043', label: '2043' },
        { value: '2044', label: '2044' },
        { value: '2045', label: '2045' },
    ];

    async function ValidaciondePago() {
        try {
            let orderID = '';
            prestamosSelected.forEach(element => {
                orderID += `${element.fiIDUnicoPrestamo}`
            });


            const settings = new Settings();
            settings.setupEndpoint("https://hn.ficoposonline.com");
            settings.setupCredentials("FH1059496235", "c32c713735c9f8c441ffaa616f8fe0a0");

            

            var cvv2 = cvv.replace('_', '');

            console.log(cvv2)
            const card = new PixelPayCard();
            card.number = cardNumber;
            card.cvv2 = cvv2;

            card.expire_month = expiryMonth;
            card.expire_year = expiryYear;
            card.cardholder = cardholder;

            const billing = new Billing();
            billing.address = billingAddress;
            billing.country = "HN";
            billing.state = "HN-CR";
            billing.city = "San Pedro Sula";
            billing.phone = billingPhone;

            const item = new Item();
            item.code = "00001";
            item.title = title;
            item.price = price;
            item.qty = 1;

            const order = new Order();
            order.id = `${orderID}`;
            order.currency = currency;
            order.customer_name = customer_name;
            order.customer_email = email;
            order.addItem(item);

            const sale = new SaleTransaction();
            sale.setOrder(order);
            sale.setCard(card);
            sale.setBilling(billing);
            const service = new Transaction(settings);

            const response = await service.doSale(sale);
            console.log(response);
            console.log(sale);

            if (response.status == 200) {
                ToastSuccessPersonalizado(response.message);
                handleContinue();
                const data = response.data
                await Abono(response.message, JSON.stringify(data), `${data.transaction_auth}`);
            }
            if (response.status == 400) {
                ToastErrorPersonalizado(response.message);
                return;
            }
            if (response.status == 401 || response.status == 403) {
                ToastErrorPersonalizado(response.message);
                return;
            }
            if (response.status == 402) {
                ToastWarningPersonalizado(response.message);
                return;
            }
            if (response.status == 404 || response.status == 405 || response.status == 406) {
                ToastWarningPersonalizado(response.message);
                return;
            }
            if (response.status == 408) {
                ToastInfo(response.message);
                return;
            }
            if (response.status == 412 || response.status == 418) {
                ToastErrorPersonalizado(response.message);
                return;
            }
            if (response.status == 422) {
                ToastWarningPersonalizado(response.message);
                return;
            }
            if (response.status == 500) {
                ToastErrorPersonalizado(response.message);
                return;
            }
            if (response.status > 500) {
                ToastErrorPersonalizado(response.message);
                return;
            }
            if (TransactionResult.validateResponse(response)) {

                const result = TransactionResult.fromResponse(response);

                const is_valid_payment = service.verifyPaymentHash(
                    result.payment_hash,
                    order.id,
                    "InternetNovanet"
                );

                if (is_valid_payment) {
                    // handleContinue();

                }
            }

        } catch (error) {
            console.error("Error en la transacción:", error);
        }
    }


    const handleCardClick = () => {
        setIsFlipped(!isFlipped);
    };

    const handleTabChange = (event, newValue) => {
        setActiveStep(newValue);
    };

    const handleCardNumberChange = (event) => {
        setCardNumber(event.target.value);
    };

    const handleExpiryMonthChange = (event) => {
        setExpiryMonth(event.target.value);
    };

    const handleExpiryYearChange = (event) => {
        setExpiryYear(event.target.value);
    };

    const handleCvvChange = (event) => {
        setCvv(event.target.value);
    };

    const handleCardholderChange = (event) => {
        setCardholder(event.target.value);
    };

    const handleBillingAddressChange = (event) => {
        setBillingAddress(event.target.value);
    };

    const handleBillingPhoneChange = (event) => {
        setBillingPhone(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleContinue = () => {
        setActiveStep(activeStep + 1)
    };

    const handlePrev = () => {
        setActiveStep(activeStep - 1)
    };

    const handleSubmitTab1 = async () => {
        try {
            setErrors({});

            await schematab1.validate(
                {
                    cardNumber,
                    cardholder,
                    email,
                    expiryMonth,
                    expiryYear,
                    cvv,
                    billingAddress,
                    billingPhone
                },
                { abortEarly: false }
            );

            if (Object.keys(errors).length === 0) {
                prestamosSelected.forEach(element => {

                    ValidaciondePago(element.value);
                });
                //
            }

        } catch (validationErrors) {
            const errors = {};
            validationErrors.inner.forEach((error) => {
                errors[error.path] = error.message;
            });

            // Actualizar el estado con los mensajes de error
            setErrors(errors);
        }
    };




    React.useEffect(() => {
        if (!id) {
            console.log('Parámetro no reconocido');
        } else {
            Getdatos(id);
        }
    }, [id]);

    const handleSetChangeData = (event, newValue) => {
        if (newValue) {

            const total = newValue.reduce((accumulator, currentValue) => {
                return accumulator + parseFloat(currentValue.value.fnValorCuotaMonedaNacional ?? 0.00);
            }, 0);
            const roundedTotal = parseFloat(total).toFixed(2);

            const totalD = newValue.reduce((accumulator, currentValue) => {
                return accumulator + parseFloat(currentValue.value.fnValorCuota ?? 0.00);
            }, 0);
            const roundedTotalD = parseFloat(totalD).toFixed(2);

            setPrestamosSelected(newValue.map((item) => item.value));
            setprice(parseFloat(roundedTotal));
            setpriceD(parseFloat(roundedTotalD));

        } else {
            setPrestamosSelected([]);
            setprice(0);
            setprice(0);
        }
    };

    const Getdatos = async (id) => {
        try {
            const data = await PagoServices.GetDataClient(id);
            const DDL = await PagoServices.PrestamosDDL(data);

            setEmail(data[0]['fcCorreo'] ?? '');
            setBillingPhone(data[0]['fcTelefono'] ?? '');
            setBillingAddress(data[0]['fcDireccionDetallada'] ?? '');
            setcustomer_name(data[0]['fcNombre'] ?? '');
            setPrestamo(data[0]['fcIDPrestamo'] ?? '');
            setfcIdentidad(data[0]['fcIdentidad'] ?? '');
            setPrestamos(DDL);
            handleSetChangeData(events, DDL.map((prestamo) => prestamo))
            setPrestamosSelected(DDL.map((prestamo) => prestamo.value));
        } catch (error) {
            console.error('Error obteniendo datos:', error);
            // Manejar el error de manera adecuada (por ejemplo, mostrar un mensaje al usuario)
        }
    };


    // const SetChangeData = async (event) => {
    //     const selectedPrestamo = event.target.value;

    //     const data = await PagoServices.GetDataClient(id);

    //     const filteredData = data.find(item => item['fcIDPrestamo'] === selectedPrestamo);

    //     if (filteredData) {
    //         setprice(filteredData['fnValorCuotaMonedaNacional'] ?? 0.00);
    //         setpriceD(data[0]['fnValorCuota'] ?? 0.00)
    //         setEmail(filteredData['fcCorreo'] ?? '');
    //         setBillingPhone(filteredData['fcTelefono'] ?? '');
    //         setBillingAddress(filteredData['fcDireccionDetallada'] ?? '');
    //         setcustomer_name(filteredData['fcNombre'] ?? '');
    //         setPrestamo(filteredData['fcIDPrestamo'] ?? '');
    //         setfcIdentidad(data[0]['fcIdentidad'] ?? '');
    //     } else {
    //         setprice(0.00);
    //         setpriceD(0.00)
    //         setEmail('');
    //         setBillingPhone('');
    //         setBillingAddress('');
    //         setcustomer_name('');
    //         setPrestamo('');
    //         setfcIdentidad('');
    //     }
    // };

    const getIPAddress = async () => {
        try {
            const response = await axios.get('https://api.ipify.org?format=json');
            return response.data.ip;
        } catch (error) {
            console.error('Error al obtener la IP:', error);
            return '';
        }
    };

    const Abono = async (pcComentarioAdicional, response, pcReferencia) => {
        try {
            const pcIP = await getIPAddress();

            for (const item of prestamosSelected) {
                const data = {
                    piIDApp: 118,
                    piIDUsuario: 337,
                    pcIP: pcIP,
                    pcIdentidad: item.fcIdentidad,
                    pnValordelAbono: item.fnValorCuota ?? 0.00,
                    pcComentarioAdicional: pcComentarioAdicional,
                    pcReferencia: pcReferencia,
                    piIDTransaccion: '',
                    pcRespuestaPOS: response
                };

                await PagoServices.Abono(data);
            }
        } catch (error) {
            console.error("Error al realizar el abono:", error);
        }
    };



    return (
        <Card sx={{ width: '100%', maxWidth: 600, margin: '40px auto' }}>
            <CardMedia
                component="img"
                height="180"
                image={EncabezadoImagen}
                alt="Encabezado de la carta"
                sx={{ objectFit: 'cover', width: '100%' }}
            />
            <Box display="flex" justifyContent="center" alignItems="center" my={4}>
                <Typography variant="h4" component="h1" textAlign={'center'}>
                    Cuota Inicial <br />
                    {customer_name || ""}
                </Typography>
            </Box>
            <Collapse in={true}>
                <CardContent
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Stack sx={{ width: '100%' }}>
                        <Tabs
                            value={activeStep}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            sx={{ alignContent: 'center', alignItems: 'center', }}
                            centered
                        >
                            <Tab sx={{ fontSize: 10 }} disabled label="Confirmación" />
                            <Tab sx={{ fontSize: 10 }} disabled label="Detalles de Facturación" />
                            <Tab sx={{ fontSize: 10 }} disabled label="Finalización" />
                        </Tabs>

                        {activeStep === 0 && (
                            <Grid container spacing={2} sx={{ maxWidth: { xs: '100%', sm: '550px' }, marginTop: '30px', margin: '0 auto', padding: '20px', backgroundColor: '#f9f9f9', color: 'white' }}>
                                {/* <Grid item xs={12}>
                                    <Autocomplete
                                        multiple
                                        options={prestamos}
                                        getOptionLabel={(option) => option.label}
                                        value={prestamos.filter((option) => prestamosSelected.includes(option.value)) || []}
                                        onChange={handleSetChangeData}
                                        renderInput={(params) => (
                                            <TextField

                                                {...params}
                                                required
                                                label="Servicios"
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                            />
                                        )}
                                        disabled={prestamos.length === 1}
                                    />
                                </Grid> */}
                                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <img src={Logo} alt="Logo" style={{ objectFit: 'cover', width: '250px', height: 'auto' }} />
                                    </Grid>
                                    <Divider sx={{ marginTop: "20px", marginBottom: "0px" }}>
                                        <Chip label="Confirmación" />
                                    </Divider>
                                    <Typography variant="body2" sx={{ color: 'black' }}>Verifique que el monto sea correcto</Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '20px' }}>
                                    <Typography variant="body2" sx={{ color: 'black' }}>Usted realizará un pago</Typography>
                                    <Typography variant="body2" sx={{ color: 'black' }}>por un total de</Typography>
                                    <Typography variant="h4" sx={{ color: '#3e7d41' }}>{`L ${price}` || 'L 0.00'}</Typography>
                                </Grid>

                                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Checkbox checked={check} sx={{ color: 'black' }} onClick={() => setcheck(!check)} />
                                    <Typography variant="body2" display="inline" sx={{ color: 'black' }}>
                                        Confirmo y Acepto el monto
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        sx={{ backgroundColor: '#0a67b1' }}
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={() => {
                                         
                                            if (!check) {
                                                ToastWarningPersonalizado('Debe aceptar los términos y condiciones');
                                                return;
                                            }
                                            handleContinue();
                                        }}
                                        endIcon={<ArrowForwardIcon />}
                                    >
                                        Continuar
                                    </Button>
                                </Grid>
                            </Grid>
                        )}

                        {activeStep === 1 && (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box className={`card-container ${isFlipped ? 'flipped' : ''}`} onClick={handleCardClick}>
                                        <div className="card" >
                                            <div className="card-front" >
                                                <div className="card-chip"></div>
                                                <div className="card-number">{cardNumber || '**** **** **** ****'}</div>
                                                <div className="card-holder">{cardholder || 'NOMBRE COMPLETO'}</div>
                                                <div className="card-expiry">{expiryMonth && expiryYear ? `${expiryMonth}/${expiryYear}` : 'MM/YY'}</div>
                                            </div>
                                            <div className="card-back">
                                                <div className="card-strip"></div>
                                                <div className="card-cvv">{cvv || 'CVV'}</div>
                                            </div>
                                        </div>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sx={{ marginTop: 5, marginBottom: 2 }}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputMask
                                        mask="9999 9999 9999 9999"
                                        value={cardNumber}
                                        onChange={handleCardNumberChange}
                                    >
                                        {() => (
                                            <TextField
                                                required
                                                label="Número de tarjeta"
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                error={!!errors.cardNumber}
                                                helperText={errors.cardNumber}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <CreditCardIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    </InputMask>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        label="Nombre del Titular de la Tarjeta"
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        value={cardholder}
                                        onChange={handleCardholderChange}
                                        error={!!errors.cardholder}
                                        helperText={errors.cardholder}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Person />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        label="Correo electrónico"
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        value={email}
                                        onChange={handleEmailChange}
                                        error={!!errors.email}
                                        helperText={errors.email}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Email />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputMask
                                        mask="+504 9999 9999"
                                        value={billingPhone}
                                        onChange={handleBillingPhoneChange}
                                    >
                                        {() => (
                                            <TextField
                                                required
                                                label="Télefono"
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                error={!!errors.billingPhone}
                                                helperText={errors.billingPhone}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Phone />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    </InputMask>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        label="Dirección"
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        value={billingAddress}
                                        onChange={handleBillingAddressChange}
                                        error={!!errors.billingAddress}
                                        helperText={errors.billingAddress}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PinDrop />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        required
                                        select
                                        label="Mes"
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        value={expiryMonth}
                                        onChange={handleExpiryMonthChange}
                                        error={!!errors.expiryMonth}
                                        helperText={errors.expiryMonth}
                                    >
                                        {months.map((month) => (
                                            <MenuItem key={month.value} value={month.value}>
                                                {month.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        required
                                        select
                                        label="Año"
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        value={expiryYear}
                                        onChange={handleExpiryYearChange}
                                        error={!!errors.expiryYear}
                                        helperText={errors.expiryYear}
                                    >
                                        {years.map((year) => (
                                            <MenuItem key={year.value} value={year.value}>
                                                {year.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={4}>
                                    <InputMask
                                        mask="9999"
                                        value={cvv}
                                        onChange={handleCvvChange}
                                    >
                                        {() => (
                                            <TextField
                                                required
                                                label="CVV2"
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                error={!!errors.cvv}
                                                helperText={errors.cvv}
                                            />
                                        )}
                                    </InputMask>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Button
                                                sx={{ backgroundColor: '#414141' }}
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                onClick={handlePrev}
                                                startIcon={<ArrowBack />}
                                            >
                                                Regresar
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                sx={{ backgroundColor: '#0a67b1' }}
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                onClick={handleSubmitTab1}
                                                endIcon={<ArrowForwardIcon />}
                                            >
                                                Continuar
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                        {activeStep === 2 && (
                            <Grid
                                container
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                                sx={{ textAlign: 'center', backgroundColor: '#f9f9f9', padding: '20px' }}
                            >
                                <Grid item>
                                    <CheckCircleIcon sx={{ fontSize: 100, color: '#3e7d41' }} />
                                </Grid>
                                <Grid item>
                                    <Typography variant="h4" gutterBottom>
                                        Pago Exitoso
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        ¡Tu pago ha sido procesado con éxito!
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Box
                                        sx={{ border: '1px solid #ccc', borderRadius: '8px', backgroundColor: '#fff', mt: 2, width: '100%', maxWidth: '600px', textAlign: 'left' }}
                                    >
                                        <Grid container spacing={2} sx={{ padding: '20px' }}>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="body1"><strong>Nro. Factura:</strong></Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="body1">{prestamo || ''}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="body1"><strong>Descripción Débito:</strong></Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="body1">{title || ''}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="body1"><strong>Cliente:</strong></Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="body1">{customer_name || ''}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="body1"><strong>Correo:</strong></Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="body1">{email || ''}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="body1"><strong>Dirección:</strong></Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="body1">{billingAddress || ''}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="body1"><strong>Teléfono:</strong></Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="body1">{billingPhone || ''}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="body1"><strong>Total:</strong></Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="body1">{price || ''} {currency || ''}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item sx={{ mt: 3 }}>
                                    {/* <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{ mt: 3 }}
                                    >
                                        Volver al Inicio
                                    </Button> */}
                                </Grid>
                            </Grid>
                        )}
                    </Stack>
                </CardContent>
            </Collapse>
            <ToastContainer />
        </Card>
    );
}

export default PrimerPago;