import axios from "axios";


function PagoService() {


  const baseURL = process.env.REACT_APP_API_URL + "api/Novanet/";

  const axiosInstance = axios.create({
    baseURL: baseURL,
  });

  async function GetDataClient(pcLlaveUnica) {
    try {
      const response = await axiosInstance.get(`${baseURL}Servicio/InformacionPago?pcLlaveUnica=${pcLlaveUnica}`);

      const data = response.data.map((item, index) => {
        return {
          key: index + 1,
          fcIDPrestamo: item.fcIDPrestamo,
          fcNombre: item.fcNombre,
          fcTelefono: item.fcTelefono,
          fcCiudad: item.fcCiudad,
          fcIdentidad: item.fcIdentidad,
          fnValorCuota: item.fnValorCuota,
          fnValorCuotaMonedaNacional: item.fnValorCuotaMonedaNacional,
          fcCorreo: item.fcCorreo,
          fcDireccionDetallada: item.fcDireccionDetallada,
          fiIDUnicoPrestamo: item.fiIDUnicoPrestamo
        };
      });
      return data;
    } catch (error) {
      console.log(error.message);
      throw error;
    }
  }

  async function PrestamosDDL(prestamos) {
    try {
      const data = prestamos.map((item) => {
        return {
          value: item,
          label: `${item.fiIDUnicoPrestamo} - L ${item.fnValorCuotaMonedaNacional}`,
        };
      });
      return data;
    } catch (error) {

    }
  }

  async function Abono(data) {
    try {

      let datos = {
        piIDApp: data["piIDApp"] ?? 118,
        piIDUsuario: data["piIDUsuario"] ?? 337,
        pcIP: data["pcIP"] ?? "",
        pcIdentidad: data["pcIdentidad"] ?? "",
        pnValordelAbono: data["pnValordelAbono"] ?? 0.00,
        // pnValordelAbono: 0.01,
        pcIDPrestamo: data["pcIDPrestamo"] ?? "",
        pcComentarioAdicional: data["pcComentarioAdicional"] ?? "",
        pcReferencia: data["pcReferencia"] ?? "",
        piIDTransaccion: data["piIDTransaccion"] ?? "",
        pcRespuestaPOS: data["pcRespuestaPOS"] ?? "",

      };

      const response = await axiosInstance.post(`${baseURL}Servicio/AplicarAbono`, datos);

      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async function Log(data) {
    try {


      let datos = {
        Pago: data["Pago"]  ??  "",
        Descripciondebito: data["Descripciondebito"]  ??  "",
        Cliente: data["Cliente"]  ?? "",
        TotalPago: data["TotalPago"]  ??  0.00,
        RespuestaApi: data["RespuestaApi"] ?? "",
        Comentario: data["Comentario"] ?? "",
      };

      const response = await axiosInstance.post(`${baseURL}Servicio/LogIntentosPago`, datos);

      return response;
    } catch (error) {
      console.log(error);
    }
  }
  return {
    GetDataClient,
    PrestamosDDL,
    Abono,
    Log
  }
}
export default PagoService;