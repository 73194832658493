import { toast } from 'react-toastify';

// TOAST DE EXITO CON MENSAJE PERSNALIZADO
export const ToastSuccessPersonalizado = (message) => {
  toast.success(message, {
    theme: 'dark',
    style: {
      backgroundColor: '#111827'
    },
    autoClose: 1500,
    closeOnClick: true
  });
}

// TOAST DE ERROR CON MENSAJE PERSONALIZADO
export const ToastErrorPersonalizado = (message) => {
  toast.error(message, {
    theme: 'dark',
    style: {
      backgroundColor: '#111827'
    },
    autoClose: 1500,
    closeOnClick: true
  });
}

// TOAST DE ADVERTENCIA CON MENSAJE PERSONALIZADO
export const ToastWarningPersonalizado = (message) => {
  toast.warning(message, {
    theme: 'dark',
    style: {
      backgroundColor: '#111827'
    },
    autoClose: 1500,
    closeOnClick: true
  });
}

// TOAST DE INFO CON MENSAJE PERSONALIZADO
export const ToastInfo = (message) => {
  toast.info(message, {
    theme: 'dark',
    style: {
      backgroundColor: '#111827'
    },
    autoClose: 1500,
    closeOnClick: true
  });
}

// TOAST DEFAULT CON MENSAJE PERSONALIZADO
export const ToastDefault = (message) => {
  toast(message, {
    theme: 'dark',
    style: {
      backgroundColor: '#111827'
    },
    autoClose: 1500,
    closeOnClick: true
  });
}