import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Card,
    CardContent,
    CardMedia,
    Button,
    Box,
    Collapse,
    Stack,
    Grid
} from '@mui/material';
import EncabezadoImagen from './Images/novanet.png';
import CreditCardIcon from '@mui/icons-material/CreditCard';

function Redireccion() {
    const navigate = useNavigate();

    return (
        <Card sx={{ width: '100%', maxWidth: 600, margin: '40px auto' }}>
            <CardMedia
                component="img"
                height="180"
                image={EncabezadoImagen}
                alt="Encabezado de la carta"
                sx={{ objectFit: 'cover', width: '100%' }}
            />
            <CardContent>
                <Collapse in={true}>
                    <CardContent
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Stack sx={{ width: '100%' }}>
                            <Grid container spacing={2} sx={{ marginTop: '30px', padding: '20px', backgroundColor: '#f9f9f9' }}>
                                <Grid item xs={12}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => navigate('/PrimerPago')}
                                        startIcon={<CreditCardIcon />}
                                        fullWidth
                                    >
                                        POS Primer Pago
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => navigate('/PagoCuota')}
                                        startIcon={<CreditCardIcon />}
                                        ze fullWidth
                                    >
                                        POS Cuota Mensual
                                    </Button>
                                </Grid>
                            </Grid>
                        </Stack>
                    </CardContent>
                </Collapse>
            </CardContent>
        </Card>
    );
}

export default Redireccion;
